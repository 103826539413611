import AppSource from '../data/app-source';
import { resetPasswordDummy } from '../utils/dummy';
import { buttonFilled, inputWIconLeading, loadPage } from '../views/templates/components';
import { iconPerson } from '../views/templates/icons';

const ResetPasswordHandler = {
  init({
    resetPassContainer,
    resetpassFormContainer,
    resetpassTimer,
  }) {
    this._insertComponent(resetpassFormContainer);
    this._timer(resetPassContainer, resetpassTimer);
    const time = Number(localStorage.getItem('TIMER_RESET_PASSWORD') ?? 0);
    if (time <= 0) this._onReset(resetPassContainer, resetpassFormContainer, resetpassTimer);
  },

  _insertComponent(resetpassFormContainer) {
    resetpassFormContainer.innerHTML += inputWIconLeading('resetpass-username', 'Input Username', 'username', 'Your Username', iconPerson, 'text');
    resetpassFormContainer.innerHTML += buttonFilled('resetpass-btn', 'Reset', 'mt-5');
  },

  _onReset(resetPassContainer, resetpassFormContainer, resetpassTimer) {
    const resetpassBtn = resetPassContainer.querySelector('#resetpass-btn');
    const resetpass = async () => {
      const username = resetPassContainer.querySelector('#resetpass-username input').value;
      const payload = { username };
      resetpassFormContainer.innerHTML += loadPage;

      const { error, message, email } = await AppSource.resetPassword(payload);
      // ------------------------- dummy -------------------------
      // const { error, message, email } = resetPasswordDummy;

      resetpassFormContainer.lastElementChild.remove();
      if (!error) {
        const successReset = `
          <div id="resetpass-message-container" class="text-center h-screen flex flex-col justify-center -mt-32">
            <p class="mb-5">Please check your email</p>
            <p class="mb-14 font-bold text-balance">${email}</p>
          </div>
        `;
        resetpassFormContainer.innerHTML = successReset;
        localStorage.setItem('TIMER_RESET_PASSWORD', 3 * 60);
        const resetpassMessageContainer = resetpassFormContainer.querySelector('#resetpass-message-container');
        resetpassMessageContainer.innerHTML += buttonFilled('login-btn', 'Login', 'mt-5');
        const loginBtn = resetpassFormContainer.querySelector('#login-btn');
        loginBtn.addEventListener('click', () => location.assign('#/login'));
      } else resetpassTimer.textContent = message;
    };
    resetpassBtn.addEventListener('click', resetpass);
  },

  _timer(resetPassContainer, resetpassTimer) {
    const resetpassBtn = resetPassContainer.querySelector('#resetpass-btn');
    const getTimer = localStorage.getItem('TIMER_RESET_PASSWORD');
    const time = Number(getTimer ?? 0);
    let tmp = time;
    if (time > 0) {
      resetpassBtn.classList.remove('bg-[#050C9C]');
      resetpassBtn.classList.add('bg-[#DCDCDC]');
      setInterval(() => {
        const c = tmp--;
        // eslint-disable-next-line no-bitwise
        const minute = (c / 60) >> 0;
        const sec = `${c - minute * 60}`;
        resetpassTimer.textContent = `You can try again in ${minute}:${sec.length > 1 ? '' : '0'}${sec}`;
        localStorage.setItem('TIMER_RESET_PASSWORD', tmp);
        if (tmp <= 0) {
          resetpassTimer.textContent = '';
          resetpassBtn.classList.remove('bg-[#DCDCDC]');
          resetpassBtn.classList.add('bg-[#050C9C]');
          location.reload();
        }
      }, 1000);
    }
  },
};

export default ResetPasswordHandler;
