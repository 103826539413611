import AppSource from '../data/app-source';
import { loginDummy } from '../utils/dummy';
import { buttonFilled, inputWIconLeading, loadPage } from '../views/templates/components';
import { iconPassword, iconPerson } from '../views/templates/icons';

const LoginHandler = {
  init({
    loginContainer,
    loginFormContainer,
    containerLoad,
  }) {
    this._insertComponent(loginFormContainer);
    this._login(loginContainer, containerLoad);
  },

  _insertComponent(loginFormContainer) {
    loginFormContainer.innerHTML += inputWIconLeading('login-username', 'Input Username', 'username', 'Your Username', iconPerson, 'text');
    loginFormContainer.innerHTML += inputWIconLeading('login-password', 'Input Password', 'password', 'Your Password', iconPassword, 'password');
    loginFormContainer.innerHTML += buttonFilled('login-btn', 'Login', 'mt-5');
    loginFormContainer.innerHTML += '<p id="login-message" class="mt-2 text-center text-red-600"></p>';
  },

  _login(loginContainer, containerLoad) {
    const loginBtn = loginContainer.querySelector('#login-btn');
    const loginMessage = loginContainer.querySelector('#login-message');
    const login = async () => {
      const username = loginContainer.querySelector('#login-username input').value;
      const password = loginContainer.querySelector('#login-password input').value;
      const payload = { username, password };
      containerLoad.innerHTML += loadPage;

      const { error, message, data } = await AppSource.login(payload);
      // ------------------------- dummy -------------------------
      // const { error, message, data } = loginDummy;

      containerLoad.lastElementChild.remove();
      if (!error) {
        localStorage.setItem('USER_NAME', data.name);
        localStorage.setItem('USER_NIM', data.username);
        localStorage.setItem('USER_ROLE', data.role);
        localStorage.setItem('USER_HOME', data.role);
        localStorage.setItem('USER_TOKEN', data.token);
        location.assign('/');
      } else loginMessage.textContent = message;
    };
    loginBtn.addEventListener('click', login);
  },
};

export default LoginHandler;
